/**
 * @typedef {Object} Banner
 * @property {string} imageUrl
 * @property {string} title
 * @property {string} link
 */

export const isConsumableCatalog = (banner) => {
  return banner !== null && (banner.title.includes('consumibles') || banner.link === 'https://anyflip.com/bebk/ingw/');
}

export const isOfferCatalog = (banner) => {
  return banner !== null && (banner.title.includes('oferta') || banner.link === 'https://anyflip.com/bebk/hvuu');
}

export const isRegularBanner = (banner) => {
  return !isConsumableCatalog(banner) && !isOfferCatalog(banner);
}