import {useRemoteData} from '../../common/utils/useRemoteData';
import {fetchProduct} from '../infrastructure/productApi';
import {useMemo} from 'react';

export const useProductRemoteData = (productCode) => {
  const fetchProductFn = useMemo(
    () => (() => fetchProduct(productCode)),
    [productCode]
  );

  const productRemoteData = useRemoteData(
    {
      initial: null,
      dataFetchingCallback: fetchProductFn
    });

  return { productRemoteData };
};