import {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {CartService} from '../services/CartService';
import type {Product} from '../models/Product';

const findGiftProduct = (cart, productId): Product => {
  const isGiftProduct = ({ packParentId }) => packParentId === productId;
  return cart.products.find(isGiftProduct) || cart.deprecatedProducts.find(isGiftProduct);
};

const CartContext = createContext();

export const CartContextProvider = ({ children }) => {
  const [cart, setCart] = useState();

  const [equivalenceChargedProducts, setEquivalenceChargedProducts] = useState();
  const [equivalenceChargeFreeProducts, setEquivalenceChargeFreeProducts] = useState();

  const [loading, setLoading] = useState(true);

  const loadCart = useCallback(async () => {
    setLoading(true);
    try {
      setCart(await CartService.get());
    } finally {
      setLoading(false);
    }
  }, []);

  const changeEquivalenceCharges = useCallback(async (product) => {
    await CartService.changeEquivalenceCharges(product);
    const updatedCart = await CartService.get();

    const giftProduct = findGiftProduct(updatedCart, product.id);
    if (giftProduct) {
      await CartService.changeEquivalenceCharges(giftProduct);
    }

    await loadCart();
  }, [loadCart]);

  const removeProduct = useCallback(async (product) => {
    return CartService.removeProduct(product).then(cart => {
      setCart(cart);
      return cart;
    });
  }, []);

  const addProduct = useCallback(async (productSelection) => {
    let updatedCart = await CartService.addProductBis({
      productId: productSelection.id,
      quantity: productSelection.quantity,
      price: productSelection.price,
      bcDiscounts: productSelection.bcDiscounts
    });

    const giftProduct = findGiftProduct(updatedCart, productSelection.id);
    if (giftProduct) {
      updatedCart = await CartService.removeProduct(giftProduct);
    }

    if (!!productSelection.giftProduct) {
      updatedCart = await CartService.addProductBis({
        productId: productSelection.giftProduct.id,
        quantity: productSelection.giftProduct.quantity,
        price: productSelection.giftProduct.price,
        bcDiscounts: productSelection.giftProduct.bcDiscounts,
        packParentId: productSelection.id
      });
    }

    setCart(updatedCart);
    return updatedCart;
  }, []);

  const loadCartBis = useCallback(async () => {
    setCart(await CartService.get());
  }, []);

  const isOnCart = useCallback(productId => {
      const products = cart?.products ?? [];
      const deprecatedProducts = cart?.deprecatedProducts ?? [];
      return [...products, ...deprecatedProducts].some(it => it?.id === productId);
    },
    [cart]);

  useEffect(() => {
    setEquivalenceChargedProducts(cart?.products?.filter(product => product.equivalenceCharge === true));
    setEquivalenceChargeFreeProducts(cart?.products?.filter(product => product.equivalenceCharge === false));
  }, [cart]);

  return (
    <CartContext.Provider value={{
      cart,
      loading,
      loadCart,
      changeEquivalenceCharges,
      equivalenceChargeFreeProducts,
      equivalenceChargedProducts,
      removeProduct,
      addProduct,
      loadCartBis,
      isOnCart
    }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = () => useContext(CartContext);