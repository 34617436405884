import {spanishDateFormatter} from '../../ui/components/SpanishDateFormatter';
import {DealType} from '../domain/DealSelection.model';
import {Table} from '../../ui/components/Table';

export const BulkDealsSelectorUI = ({ lots, onDealSelection, selection }) => {
  if (!lots?.length) return <></>;

  /** @type {TableColumn[]} */
  const columns = [
    {
      header: "", // Checkbox column
      render: (lot) => (
        <input
          type="radio"
          name="bulkDeal"
          checked={selection?.value === lot}
          onChange={() => onDealSelection({ type: DealType.LOT, value: lot })}
        />
      ),
    },
    {
      header: "Precio",
      render: (lot) => `${lot.price.value.toFixed(2)}€`,
    },
    {
      header: "Descuento",
      render: (lot) => `${lot.price.discountLine}`,
    },
    {
      header: "Precio Neto",
      render: (lot) => `${lot.price.bestPrice.toFixed(2)}€`,
    },
    {
      header: "Stock lote",
      render: (lot) => lot.stock,
    },
    {
      header: "Fecha de caducidad",
      render: (lot) => spanishDateFormatter(lot.expirationDate),
    },
  ];

  return (
    <div>
      <strong className="m-0 text-primary">Promociones por lotes</strong>
      <Table columns={columns} data={lots} />
    </div>
  );
};