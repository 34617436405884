import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const CarouselContainer = styled.div`
  width: 100%;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CarouselInner = styled.div`
  width: 100%;
  height: 400px;
  overflow: hidden;

  @media (max-width: 768px) {
    height: auto;
    max-height: 300px;
  }
`;

const CarouselItem = styled.div`
  width: 100%;
  height: 100%;
  display: none;

  &.active {
    display: block;
  }
`;

const CarouselImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 20px;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    max-height: 100%;
  }
`;

const CarouselLink = styled.a`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const IndicatorsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 15px;

  @media (max-width: 768px) {
    gap: 5px;
    margin-top: 10px;
  }
`;

const Indicator = styled.button`
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--bs-primary);
  cursor: pointer;
  transition: background-color 0.3s ease;

  &.active {
    background-color: var(--bs-primary);
  }
`;

export const BannerSliderUI = ({ banners }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  // Auto-slide every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveItemIndex((prevIndex) =>
        prevIndex === banners.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [banners.length]);

  return (
    <CarouselContainer>
      <CarouselInner>
        {banners.map((banner, index) => (
          <CarouselItem
            key={index}
            className={index === activeItemIndex ? 'active' : ''}
          >
            {!!banner.link ? (
              <CarouselLink href={banner.link} target="_blank" rel="noopener noreferrer">
                <CarouselImage src={banner.imageUrl} alt={banner.title} isClickable />
              </CarouselLink>
            ) : (
               <CarouselImage src={banner.imageUrl} alt={banner.title} />
             )}
          </CarouselItem>
        ))}
      </CarouselInner>

      <IndicatorsContainer>
        {banners.map((_, index) => (
          <Indicator
            key={index}
            className={index === activeItemIndex ? 'active' : ''}
            onClick={() => setActiveItemIndex(index)}
          />
        ))}
      </IndicatorsContainer>
    </CarouselContainer>
  );
};
