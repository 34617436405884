import {DealType} from '../domain/DealSelection.model';

export const GiftDealsSelectorUI = ({
  promos,
  onDealSelection,
  selection
}) => {
  if (promos.length === 0) {
    return <></>;
  }
  return (
    <div>
      {
        promos?.length > 0 && (
          <div>
            <strong className="m-0 text-primary">
              Promociones con obsequios
            </strong>
            <div className="table-responsive">
              <table className="table table-borderless">
                <thead>
                <tr>
                  <th>{/*Checkbox*/}</th>
                  <th>Descripción</th>
                  <th>Cant. min.</th>
                  <th>Precio obsequio</th>
                  <th>Precio pack</th>
                </tr>
                </thead>
                <tbody className="fw-normal">
                {
                  promos.map((promo, index) => (
                    <tr key={index}>
                      <td>
                        <input type="radio"
                               name="giftDeal"
                               checked={selection?.value === promo}
                               onChange={() => onDealSelection({ type: DealType.PROMO, value: promo })}
                        />
                      </td>
                      <td>{promo.description}</td>
                      <td>{promo.minPurchaseForPromo} Uds</td>
                      <td>{promo.price.bestPrice.toFixed(2)}€</td>
                      <td>{promo.packPrice.toFixed(2)}€</td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
            </div>
          </div>
        )
      }
    </div>
  );
};