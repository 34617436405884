import {NameProduct} from '../../ui';
import React, {useMemo, useState} from 'react';
import {ProductPriceUI} from './ProductPriceUI';
import {AddToFavoritesButton} from './AddToFavoritesButton';
import {AddToCartProxy} from './AddToCartProxy';
import {NumberInputUI} from '../../ui/components/NumberInputUI';
import {AvailabilityUI} from '../../common/ui/AvailabilityUI';
import {TagListUI} from '../../common/ui/TagListUI';
import {useNavigate} from 'react-router-dom';
import {useProductSelection} from '../application/useProductSelection';
import {generateItemTags} from '../../catalog/domain/CatalogService';

export const ProductFlatPurchaseCardUI = ({ details }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { productSelection, setQuantity } = useProductSelection(details, 1);

  const tags = useMemo(() => generateItemTags(details), [details]);
  const navigate = useNavigate();

  return (
    <>
      <div className='rounded-2 p-1 shadow-sm'
           onMouseEnter={() => setIsHovered(true)}
           onMouseLeave={() => setIsHovered(false)}
      >
        <div className="row justify-content-start align-items-center">
          <div className="col-12 col-md-2 col-xl-auto">
            <div className="d-flex justify-content-center align-items-center">
              <div className="position-absolute">
                <AddToFavoritesButton data={{
                  productCode: details.code,
                  show: isHovered,
                  isFavorite: details.isFavorite
                }} />
              </div>
              <img src={details.imageUrl}
                   onClick={() => navigate(`/articulos/${encodeURIComponent(details.code)}`)}
                   style={{ height: '65px', width: '65px' }}
                   className="rounded"
                   alt="Imagen del producto"
              />
            </div>
          </div>

          <div className="col-12 col-md-8 col-xl">
            <div className="row gap-2 gap-lg-0">
              <div className="col-12 col-lg-8 col-xl-8">
                <div className="d-flex flex-row justify-content-between"
                     style={{ cursor: 'pointer' }}
                     onClick={() => navigate(`/articulos/${encodeURIComponent(details.code)}`)}>
                  <div className="d-flex flex-column">
                    <TagListUI data={{ tags }} />
                    <NameProduct>
                      {details.name}
                      <small className="text-muted ms-1">Ref: {details.code}</small>
                    </NameProduct>
                    <AvailabilityUI data={{ availability: productSelection.availability }} />
                  </div>

                  <ProductPriceUI price={{
                    sellingPrice: details.price?.bestPrice,
                    basePrice: details.price?.value
                  }} />
                </div>
              </div>

              <div className="col-12 col-lg-4 col-xl-4">
                <div className="d-flex flex-row gap-2 justify-content-center">
                  <div className="">
                    <NumberInputUI data={{ value: productSelection.quantity }} actions={{ setValue: setQuantity }} />
                  </div>

                  <div className="">
                    <AddToCartProxy
                      data={{
                        details: details,
                        quantity: productSelection.quantity,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};