import {ProductPriceUI} from './ProductPriceUI';

export const PriceBreakdownUI = ({
  price,
  discount
}) => {
  return (
    <div className="d-flex flex-row align-items-center justify-content-start">
      {
        discount && (
          <>
            <div className="d-flex gap-2">
              {
                discount?.percentage > 0 && (
                  <div className="d-flex flex-column align-items-center">
                    <small className="text-muted">%&nbsp;ahorro</small>
                    <strong className="text-primary">
                      <strong className="fs-5">{discount.percentage.toFixed(2)}%</strong>
                    </strong>
                  </div>
                )
              }
              {
                discount?.value > 0 && (
                  <div className="d-flex flex-column align-items-center">
                    <small className="text-muted">Ahorro</small>
                    <strong className="text-primary">
                      <strong className="fs-5">{discount.value.toFixed(2)}€</strong>
                    </strong>
                  </div>
                )
              }
            </div>
            <div className="vr mx-2"></div>
          </>
        )
      }
      {
        price?.sellingPrice > 0 ? (
          <div className="d-flex gap-2">
            <div className="d-flex flex-column align-items-center">
              <small>Total</small>
              <strong>
                <ProductPriceUI price={price} />
              </strong>
            </div>
          </div>
        ) : (
          <span>Precio online no disponible</span>
        )
      }
      <div className="vr mx-2"></div>
    </div>
  );
};