import {ExclusiveDealsSelectorUI} from './ExclusiveDealsSelectorUI';
import {BulkDealsSelectorUI} from './BulkDealsSelectorUI';
import {GiftDealsSelectorUI} from './GiftDealsSelectorUI';

export const DealSelector = ({ product, selection, onSelectionChange }) => {
  return (
    <div className="fw-lighter">
      <ExclusiveDealsSelectorUI selection={selection} offers={product.offers} onDealSelection={onSelectionChange} />
      <GiftDealsSelectorUI selection={selection} promos={product.promos} onDealSelection={onSelectionChange} />
      <BulkDealsSelectorUI selection={selection} lots={product.lots} onDealSelection={onSelectionChange} />
      {
        product.hasDealsToChoose && (
          <div className="px-2 d-flex flex-row gap-3 justify-content-start align-items-center">
            <input type="radio"
                   name="noDeal"
                   checked={!selection}
                   onChange={(event) => {
                     event.target.checked && onSelectionChange(null);
                   }} />
            <strong>No quiero ninguna promoción</strong>
          </div>
        )
      }
    </div>
  );
};