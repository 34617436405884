import {useEffect, useState} from 'react';
import {createProductSelectionHandler} from '../domain/ProductSelection.model';

const DEFAULT_QUANTITY = 1;

export const useProductSelection = (productDetails, defaultQuantity = DEFAULT_QUANTITY) => {
  const [quantity, setQuantity] = useState(defaultQuantity);
  const [dealSelection, setDealSelection] = useState(() => null);

  const buildProductSelection = createProductSelectionHandler(productDetails, quantity);

  const [productSelection, setProductSelection] = useState(() => buildProductSelection(productDetails, quantity));

  useEffect(() => {
    setProductSelection(buildProductSelection(dealSelection, quantity));
  }, [dealSelection, quantity]);

  return { productSelection, dealSelection, setQuantity, setDealSelection };
};