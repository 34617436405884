import {useRemoteData} from '../../common/utils/useRemoteData';
import {fetchAllBanners} from '../services/BannerFetcher';
import {useMemo} from 'react';
import {mapRemoteData} from '../../common/domain/RemoteData.model';
import {isConsumableCatalog, isOfferCatalog, isRegularBanner} from '../domain/Banner.model';

export const useBannersRemoteData = () => {
  const allBannersRemoteData =  useRemoteData({
    initialData: [],
    dataFetchingCallback: fetchAllBanners
  });

  const catalogsRemoteData = useMemo(() => {
    return mapRemoteData(allBannersRemoteData, (banners) => {
      const consumables = banners?.find(isConsumableCatalog);
      const offers = banners?.find(isOfferCatalog);
      return {consumables, offers};
    });
  }, [allBannersRemoteData]);

  const bannersRemoteData = useMemo(() => {
    return mapRemoteData(allBannersRemoteData, (banners) => banners?.filter(isRegularBanner));
  }, [allBannersRemoteData]);

  return { catalogsRemoteData, bannersRemoteData };
}