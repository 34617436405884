export const ProductTagsUI = ({ productDetails }) => {
  return (
    <div className="d-flex flex-row align-items-center gap-1 flex-wrap">
      {
        productDetails.pro_nuevo === true && (
          <span className="badge text-primary border border-primary">
            Nuevo
          </span>
        )
      }
      {
        productDetails.featured === true && (
          <span className="badge text-primary border border-primary">
            Destacado
          </span>
        )
      }
      {
        productDetails.bestSeller === true && (
          <span className="badge text-primary border border-primary">
            Más vendido
          </span>
        )
      }
    </div>
  );
};