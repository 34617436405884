import {useBannersRemoteData} from '../application/useBannersRemoteData';
import {createContext, useContext} from 'react';

const BannersContext = createContext();

export const useBannersContext = () => useContext(BannersContext);

export const BannersContextProvider = ({ children }) => {
  const context = useBannersRemoteData();
  return (
    <BannersContext.Provider value={context}>
      {children}
    </BannersContext.Provider>
  );
};
