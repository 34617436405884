import {RemoteDataUI} from '../../common/ui/RemoteDataUI';
import {Spinner} from 'react-bootstrap';
import React from 'react';
import {BannerSliderUI} from './BannerSliderUI';
import {useBannersContext} from '../infrastructure/BannersContext';

export const BannerSlider = () => {
  const { bannersRemoteData } = useBannersContext();
  return (
    <RemoteDataUI remoteData={bannersRemoteData}>
      <RemoteDataUI.Pending>
        <div className="text-center"><Spinner /></div>
      </RemoteDataUI.Pending>

      <RemoteDataUI.Success>
        {banners => <BannerSliderUI banners={banners} />}
      </RemoteDataUI.Success>
    </RemoteDataUI>
  );
};