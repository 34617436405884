import React, {useEffect} from 'react';
import {CarouselLabs} from '../../ui';
import {useDocumentTitle} from '../../components/Theme/useDocumentTitle';
import {toast} from 'react-toastify';
import {FeaturedProducts} from '../../catalog/ui/FeaturedProducts';
import {useFilterContext} from '../../catalog/infrastructure/FilterContext';
import {BannerSlider} from '../ui/BannerSlider';
import {Catalogs} from '../ui/Catalogs';
import {BannersContextProvider} from '../infrastructure/BannersContext';
import {FavoriteProducts} from '../../catalog/ui/FavoriteProducts';


function Home() {
  useDocumentTitle('Inicio');
  const { clearFilters } = useFilterContext();
  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const message = localStorage.getItem('message');
    if (isLoggedIn) {
      toast.success(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2500
      });
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('message');
    }
    clearFilters();
  }, [clearFilters]);
  return (
    <BannersContextProvider>
      <section className="container justify-content-center">
        <div style={{ marginTop: '20px' }}>
          <BannerSlider />
        </div>
        <div style={{ marginTop: '2rem' }}>
          <FavoriteProducts />
        </div>
        <div style={{ marginTop: '2rem' }}>
          <FeaturedProducts />
        </div>
        <div style={{ marginTop: '2rem' }}>
          <Catalogs />
        </div>
        <div style={{ marginTop: '2rem' }}>
          <CarouselLabs />
        </div>
      </section>
    </BannersContextProvider>
  );
}

export {
  Home
};