/**
 * @typedef {Object} ProductSelection
 * @property {number} id - The unique identifier for the selected product.
 * @property {Price?} price - Price details of the product.
 * @property {number} quantity - The quantity of the product selected.
 * @property {Discount} discount - Discount details applied to the product.
 * @property {BcDiscounts} bcDiscounts - Breakdown of additional discounts.
 * @property {GiftProduct?} giftProduct - Optional gift product details.
 * @property {Availability} availability - Indicates whether the requested quantity of the product is available for purchase.
 * @property {DealType} dealType - Chosen deal
 */

/**
 * @typedef {Object} Price
 * @property {number} basePrice - The base price of the product.
 * @property {number} sellingPrice - The selling price after discounts.
 */

/**
 * @typedef {Object} Discount
 * @property {number} value - The discount value in currency.
 * @property {string} percentage - The discount percentage.
 * @property {string} text - Descriptive text for the discount.
 */

/**
 * @typedef {Object} BcDiscounts
 * @property {number} bcDisc1 - Discount condition 1.
 * @property {number} bcDisc2 - Discount condition 2.
 * @property {number} bcDisc3 - Discount condition 3.
 * @property {number} bcDisc4 - Discount condition 4.
 * @property {number} bcDisc5 - Discount condition 5.
 * @property {number} bcDisc6 - Discount condition 6.
 * @property {number} bcDisc7 - Discount condition 7.
 * @property {number} bcDisc8 - Discount condition 8.
 * @property {number} bcDisc9 - Discount condition 9.
 * @property {number} bcDisc10 - Discount condition 10.
 */

import {DealType} from './DealSelection.model';
import {findItemAvailability} from '../../catalog/domain/CatalogService';

/**
 * @typedef {Object} GiftProduct
 * @property {number} id - The unique identifier for the gift product.
 * @property {number} quantity - Quantity of the gift product.
 * @property {Price} price - Price details for the gift product.
 * @property {BcDiscounts} bcDiscount - Additional discounts for the gift product.
 */


export const NoBcDiscounts = {
  bcDisc1: 0,
  bcDisc2: 0,
  bcDisc3: 0,
  bcDisc4: 0,
  bcDisc5: 0,
  bcDisc6: 0,
  bcDisc7: 0,
  bcDisc8: 0,
  bcDisc9: 0,
  bcDisc10: 0
};

// TODO: fix
/**
 * @param {Product} productDetails
 * @param {Lot} lot
 * @param quantity
 * @returns {ProductSelection}
 */
export const buildWithLot = (productDetails, lot, quantity) => {
  const price = {
    basePrice: lot.price.value,
    sellingPrice: lot.price.bestPrice
  };
  const discount = {
    value: lot.price.savings * quantity,
    percentage: lot.price.discount,
    text: lot.price.discountLine
  };
  let bcDiscounts = lot.price.bcDiscounts;
  return {
    id: productDetails.id,
    price,
    quantity,
    bcDiscounts,
    discount,
    dealType: DealType.LOT,
    availability: buildLotAvailability(productDetails, lot, quantity)
  };
};

const buildLotAvailability = (productDetails, lot, quantity) => {
  const msg = `${lot.stock} unidades disponibles para el lote`;
  if (quantity > lot.stock) {
    return { isAvailable: false, msg }
  }
  return { isAvailable: true, msg };
}

/**
 * @param {Product} productDetails
 * @param {Offer} offer
 * @param {number} quantity
 * @returns {ProductSelection}
 */
export const buildWithOffer = (productDetails, offer, quantity) => {
  console.log(JSON.stringify(offer));
  return {
    id: productDetails.id,
    price: buildOfferPrice(productDetails, offer),
    discount: buildOfferDiscount(productDetails, offer, quantity),
    bcDiscounts: buildOfferBcDiscount(productDetails, offer),
    quantity,
    availability: findItemAvailability(productDetails, quantity),
    dealType: DealType.OFFER
  };
};

const buildOfferPrice = (product, offer) => {
  return product.price && {
    basePrice: offer.price.value,
    sellingPrice: offer.price.bestPrice
  };
};

const buildOfferDiscount = (product, offer, quantity) => {
  return offer.price && {
    value: offer.price.savings * quantity,
    percentage: offer.price.discount,
    text: offer.price.discountLine
  };
};

const buildOfferBcDiscount = (productDetails, offer) => {
  let result = NoBcDiscounts;
  if (!!offer.price) {
    result = {
      ...NoBcDiscounts,
      ...offer.price.bcDiscounts
    };
  }
  return result;
};

/**
 * @param {Product} product
 * @param {Promo} promo
 * @param {number} quantity
 * @returns {ProductSelection}
 */
export const buildWitPromo = (product, promo, quantity) => {
  let price = {
    sellingPrice: promo.productPrice.bestPrice,
    basePrice: promo.productPrice.value
  };
  const discount = {
    value: promo.productPrice.savings * quantity,
    percentage: promo.productPrice.discount,
    text: promo.productPrice.discountLine
  };
  let bcDiscounts = promo.productPrice.bcDiscounts;
  const giftProduct = {
    id: promo.promoProductId,
    quantity: Math.floor(quantity / promo.minPurchaseForPromo) * promo.stock,
    price: { sellingPrice: promo.price.bestPrice, basePrice: promo.price.value },
    bcDiscounts: promo.price.bcDiscounts
  };
  return {
    id: product.id,
    price,
    quantity,
    bcDiscounts,
    discount,
    giftProduct,
    availability: findItemAvailability(product, quantity),
    dealType: DealType.PROMO
  };
};

/**
 * @param {Product} product
 * @param quantity
 * @returns {ProductSelection}
 */
export const buildWithoutDeal = (product, quantity) => {
  return {
    id: product.id,
    price: buildPrice(product),
    discount: buildDiscount(product, quantity),
    bcDiscounts: buildBcDiscounts(product),
    quantity,
    availability: findItemAvailability(product, quantity),
    dealType: DealType.NONE
  };
};

/**
 * @param {Product} product
 * @returns {{sellingPrice: (number|number|*), basePrice: (number|number|*)}}
 */
const buildPrice = (product) => {
  const price = product.price;
  return price && {
    basePrice: price.value,
    sellingPrice: price.bestPrice
  };
};

const buildDiscount = (product, quantity) => {
  const price = product.price;
  return price && {
    value: price.savings * quantity,
    percentage: price.discount,
    text: price.discountLine
  };
};

const buildBcDiscounts = (product) => {
  const price = product.price;
  let result = NoBcDiscounts;
  if (!!price) {
    result = {
      ...product.price.bcDiscounts,
      bcDisc9: price.bcDisc9,
      bcDisc10: price.bcDisc10
    };
  }
  return result;
};

export const createProductSelectionHandler = (product, initialQuantity) => {
  let prevQuantity = initialQuantity ?? 0;
  return (deal, newQuantity) => {
    let result;
    switch (deal?.type) {
      case DealType.LOT:
        result = buildWithLot(product, deal.value, prevQuantity);
        break;
      case DealType.PROMO:
        const adjustedQuantity = adjustToNearestChunk(prevQuantity, newQuantity, deal.value.minPurchaseForPromo);
        result = buildWitPromo(product, deal.value, adjustedQuantity);
        break;
      case DealType.OFFER:
        const minQuantity =  newQuantity < deal.value.minQuantity ? deal.value.minQuantity : newQuantity;
        result = buildWithOffer(product, deal.value, minQuantity);
        break;
      default:
        result = buildWithoutDeal(product, prevQuantity);
    }
    prevQuantity = newQuantity;
    return result;
  };
};

const adjustToNearestChunk = (prevValue, newValue, chunk) => {
  if (newValue < chunk) {
    return chunk;
  }
  const chunks = Math.ceil(Math.abs(newValue - prevValue) / chunk);
  if (prevValue < newValue) {
    return prevValue + (chunks * chunk);
  }
  return prevValue - (chunks * chunk);
};
