import {useParams} from 'react-router-dom';
import {ProductPage} from '../product/ui/ProductPage';
import {RelatedCatalogItemsSwiper} from '../catalog/ui/RelatedCatalogItemsSwiper';

export const ProductDetailsWithRelatedCatalogItemsPage = () => {
  const { productCode } = useParams();

  return (
    <>
      <ProductPage />
      <div style={{ marginTop: '100px' }}>
        <RelatedCatalogItemsSwiper data={{ productCode }} />
      </div>
    </>
  );
};