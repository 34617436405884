import {DealType} from '../domain/DealSelection.model';

export const ExclusiveDealsSelectorUI = ({
  offers = [],
  onDealSelection,
  selection
}) => {
  if (offers.length === 0) {
    return <></>;
  }
  return (
    <div>
      <strong className="m-0 text-primary">Ofertas exclusivas</strong>
      <div className="table-responsive">
        <table className="table table-borderless">
          <thead>
          <tr>
            <th>{/*Checkbox*/}</th>
            <th>Cantidad minima</th>
            <th>Descuento</th>
            <th>Precio neto</th>
          </tr>
          </thead>
          <tbody className="fw-normal">
          {
            offers.map((offer, index) => (
              <tr key={index}>
                <td>
                  <input type="radio"
                         name="exlusiveDeal"
                         checked={selection?.value === offer}
                         onChange={() => onDealSelection({ type: DealType.OFFER, value: offer })}
                  />
                </td>
                <td>{offer.minQuantity} Uds</td>
                <td>{offer.price.discount.toFixed(2)}%</td>
                <td>{offer.price.bestPrice.toFixed(2)}€</td>
              </tr>
            ))
          }
          </tbody>
        </table>
      </div>
    </div>
  );
};