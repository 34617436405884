import {buildDealTag, buildInfoTag} from '../../common/domain/Tag.model.ts';
import {mapRemoteData} from '../../common/domain/RemoteData.model';

export const generateItemTags = (catalogItem) => {
  const tags = [];
  if (catalogItem?.new) {
    tags.push(buildInfoTag('Nuevo'));
  }
  if (catalogItem?.featured) {
    tags.push(buildInfoTag('Destacado'));
  }
  if (catalogItem?.bestSeller) {
    tags.push(buildInfoTag('Más vendido'));
  }
  if (catalogItem?.hasDeals) {
    tags.push(buildDealTag('Ofertas'));
  }
  return tags;
}

// TODO: createa js docs
export const findItemAvailability = (
  catalogItem,
  requiredQuantity?
) => {
  if (!catalogItem) {
    return null;
  }
  if (shouldShowLastUnitsMessage(catalogItem)) {
    return createAvailability(true, getLastUnitsMessage(catalogItem));
  }
  if (isOutOfStock(catalogItem)) {
    return createAvailability(false, getOutOfStockMessage(catalogItem));
  }
  if (shouldCheckStock(catalogItem, requiredQuantity)) {
    return createAvailability(true, 'Producto disponible');
  }
  if (isInsufficientStock(catalogItem, requiredQuantity)) {
    return createAvailability(false, getInsufficientStockMessage(catalogItem));
  }
  return createAvailability(true, `${catalogItem.availableUnits} unidades disponibles`);
};

const shouldShowLastUnitsMessage = (catalogItem) => {
  return catalogItem.showLastUnitsMessage;
};

const getLastUnitsMessage = (catalogItem) => {
  return catalogItem.lastUnitsMessage || 'Últimas unidades disponibles';
};

const isOutOfStock = (catalogItem) => {
  return catalogItem.availableUnits <= 0;
};

const getOutOfStockMessage = (catalogItem) => {
  return catalogItem.stockText || 'Disponible próximamente';
};

const shouldCheckStock = (catalogItem, requiredQuantity) => {
  return catalogItem.showStockText === false && (!requiredQuantity || requiredQuantity <= catalogItem.availableUnits);
};

const isInsufficientStock = (catalogItem, requiredQuantity) => {
  return !!requiredQuantity && requiredQuantity > catalogItem.availableUnits;
};

const getInsufficientStockMessage = (catalogItem) => {
  return catalogItem.stockText || 'Stock insuficiente';
};

const createAvailability = (isAvailable, msg) => {
  return { isAvailable, msg };
};
