import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

/** Constants */
const GAP = 20;
const FALLBACK_ITEM_WIDTH = 300;  // Fallback if measured width is 0
const FALLBACK_ITEM_HEIGHT = 200; // Fallback if measured height is 0

/** Styled Components */
const SwiperWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const SwiperContainer = styled.div`
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  position: relative;
  /** Dynamically set the height using the prop from state */
  height: ${({ containerHeight }) => `${containerHeight}px`};
`;

const Inner = styled.div`
  display: flex;
  gap: ${GAP}px;
  transition: transform 0.5s ease-in-out;
  transform: translateX(${({ offset }) => `-${offset}px`});
  width: ${({ totalItems, itemWidth }) => totalItems * (itemWidth + GAP)}px;
`;

const Item = styled.div`
  flex: 0 0 auto;
  width: ${({ itemWidth }) => `${itemWidth}px`};
  box-sizing: border-box;
`;

const InnerChildWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  color: var(--bs-primary);

  &:hover {
    transform: scale(1.1);
  }
`;

const PrevArrow = styled(ArrowButton)`
  margin-right: 10px;
`;

const NextArrow = styled(ArrowButton)`
  margin-left: 10px;
`;

/** Swiper Item Component */
export const SwiperItem = ({ children }) => {
  return <>{children}</>;
};

/** Swiper Main Component */
export const Swiper = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [itemWidth, setItemWidth] = useState(FALLBACK_ITEM_WIDTH);

  // NEW: Track container height
  const [containerHeight, setContainerHeight] = useState(FALLBACK_ITEM_HEIGHT);

  const containerRef = useRef(null);
  const firstChildRef = useRef(null);

  const swiperItems = React.Children.toArray(children).filter(
    (child) => child.type === SwiperItem
  );

  const totalSlides = Math.ceil(swiperItems.length / itemsPerPage);

  const calculateItemsPerPage = (containerW, childW, gap) => {
    return Math.floor(containerW / (childW + gap)) || 1;
  };

  const updateSize = () => {
    if (!containerRef.current || !firstChildRef.current) return;

    const containerW = containerRef.current.offsetWidth;

    // Access the actual child node inside the first SwiperItem
    const childNode = firstChildRef.current.children[0];

    if (childNode) {
      // Measure the child's width & height
      const measuredWidth = childNode.offsetWidth || FALLBACK_ITEM_WIDTH;
      const measuredHeight = childNode.offsetHeight || FALLBACK_ITEM_HEIGHT;

      // Update itemWidth
      setItemWidth(measuredWidth);
      // Update containerHeight, e.g. 20px taller than the item
      setContainerHeight(measuredHeight + 20);

      // Now calculate how many items fit per "page"
      setItemsPerPage(calculateItemsPerPage(containerW, measuredWidth, GAP));
    }
  };

  useEffect(() => {
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiperItems.length]);

  const handleNext = () => {
    setActiveIndex((prev) => (prev + 1 < totalSlides ? prev + 1 : 0));
  };

  const handlePrevious = () => {
    setActiveIndex((prev) => (prev - 1 >= 0 ? prev - 1 : totalSlides - 1));
  };

  const offset = activeIndex * itemsPerPage * (itemWidth + GAP);
  const shouldShowArrows = totalSlides > 1;

  return (
    <SwiperWrapper>
      {shouldShowArrows && (
        <PrevArrow onClick={handlePrevious}>
          <ArrowBackIosIcon />
        </PrevArrow>
      )}

      {/** Pass containerHeight to styled component */}
      <SwiperContainer ref={containerRef} containerHeight={containerHeight}>
        <Inner offset={offset} totalItems={swiperItems.length} itemWidth={itemWidth}>
          {swiperItems.map((child, index) => (
            <Item key={index} itemWidth={itemWidth}>
              <InnerChildWrapper ref={index === 0 ? firstChildRef : null}>
                {child}
              </InnerChildWrapper>
            </Item>
          ))}
        </Inner>
      </SwiperContainer>

      {shouldShowArrows && (
        <NextArrow onClick={handleNext}>
          <ArrowForwardIosIcon />
        </NextArrow>
      )}
    </SwiperWrapper>
  );
};

Swiper.Item = SwiperItem;
