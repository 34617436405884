import {useBannersContext} from '../infrastructure/BannersContext';
import {RemoteDataUI} from '../../common/ui/RemoteDataUI';
import {Spinner} from 'react-bootstrap';
import styled from 'styled-components';
import React from 'react';

const CatalogImage = styled.img`
  width: 450px;
  height: 300px;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;

export const Catalogs = () => {
  const { catalogsRemoteData } = useBannersContext();

  return (
    <RemoteDataUI remoteData={catalogsRemoteData}>
      <RemoteDataUI.Pending>
        <div className="text-center"><Spinner /></div>
      </RemoteDataUI.Pending>

      <RemoteDataUI.Success>
        {catalogs => (!!catalogs.offers || !!catalogs.consumables) && (
          <section className="d-flex flex-column gap-4 align-items-center">
            <p className="fw-bold fs-5">Descubre nuestros catálogos</p>
            <div className="d-flex flex-column flex-md-row gap-4 align-items-center justify-content-center">
              {!!catalogs.offers && (
                <a href={catalogs.offers.link} target="_blank" rel="noopener noreferrer">
                  <CatalogImage src={catalogs.offers.imageUrl} alt="catálogo de ofertas" />
                </a>
              )}
              {!!catalogs.consumables && (
                <a href={catalogs.consumables.link} target="_blank" rel="noopener noreferrer">
                  <CatalogImage src={catalogs.consumables.imageUrl} alt="catálogo de consumibles" />
                </a>
              )}
            </div>
          </section>
        )}
      </RemoteDataUI.Success>
    </RemoteDataUI>
  );
};