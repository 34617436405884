import {ProductSwiper} from './ProductSwiper';
import {fetchAll} from '../infrastructure/CatalogApi';
import {mapRemoteData} from '../../common/domain/RemoteData.model';

/**
 * @param {number} data.productCode Catalog Item code
 * @returns {JSX.Element}
 * @constructor
 */
export const RelatedCatalogItemsSwiper = ({ data }) => {
  const { productCode } = data;

  const fetchFn = () =>
    fetchAll({ relatedToProductCode: productCode }, { amount: 50, page: 0 })
    .then(rd => mapRemoteData(rd, ({ content }) => content));
  return (
    <ProductSwiper title="PRODUCTOS RELACIONADOS" fetchFn={fetchFn} />
  );
};
