import type {Cart} from '../models/Cart';
import {CartRepository} from '../repositories/CartRepository';
import ApiClient from '../../API/api';

const ADD_PRODUCT_ENDPOINT = 'cart/product';

export class CartService {
  static async changeEquivalenceCharges(product): Promise<void> {
    return CartRepository.setEquivalenceCharge(product.id, !product.equivalenceCharge);
  }

  static get(): Promise<Cart> {
    return CartRepository.get();
  }

  static removeProduct(product): Promise<void> {
    return CartRepository.removeProduct(product.id);
  }

  static addProductBis = ({ productId, quantity, price, bcDiscounts, packParentId = null }) => {
    return ApiClient.performRequest(
      ADD_PRODUCT_ENDPOINT,
      {
        method: 'POST',
        body: {
          productId: productId,
          quantity: quantity,
          sellingPrice: price.sellingPrice,
          basePrice: price.basePrice,
          packParentId: packParentId,
          bcDiscounts: {
            descBc1: bcDiscounts.bcDisc1,
            descBc2: bcDiscounts.bcDisc2,
            descBc3: bcDiscounts.bcDisc3,
            descBc4: bcDiscounts.bcDisc4,
            descBc5: bcDiscounts.bcDisc5,
            descBc6: bcDiscounts.bcDisc6,
            descBc7: bcDiscounts.bcDisc7,
            descBc8: bcDiscounts.bcDisc8,
            descBc9: bcDiscounts.bcDisc9,
            descBc10: bcDiscounts.bcDisc10
          }
        }
      }
    );
  };
}