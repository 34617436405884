import {useParams} from 'react-router-dom';
import React from 'react';
import {useProductRemoteData} from '../application/useProductRemoteData';
import {RemoteDataUI} from '../../common/ui/RemoteDataUI';
import {Spinner} from 'react-bootstrap';
import {ProductDetailsUI} from './ProductDetailsUI';
import {RoutePathBreadcrumb} from '../../ui';
import {PurchasePanel} from './PurchasePanel';

export const ProductPage = () => {
  const { productCode } = useParams();
  const { productRemoteData } = useProductRemoteData(productCode);

  return (
    <RemoteDataUI remoteData={productRemoteData}>
      <RemoteDataUI.Pending>
        <div className="text-center">
          <Spinner />
        </div>
      </RemoteDataUI.Pending>

      <RemoteDataUI.Success>
        {
          product => (
            <div className="container">
              <RoutePathBreadcrumb customBones={{ [productCode]: { name: product.name, path: `/articulos/${productCode}` } }} />

              <div className="row justify-content-center">
                <div className="col-12 col-md-6">
                  <PurchasePanel product={product} />
                </div>
                <div className="col-md-6 order-md-first rounded-3">
                  <ProductDetailsUI product={product} />
                </div>
              </div>
            </div>
          )
        }
      </RemoteDataUI.Success>

      <RemoteDataUI.Failure>
        <div className="alert alert-danger">
          Imposible cargar producto. Intentelo más tarde
        </div>
      </RemoteDataUI.Failure>;
    </RemoteDataUI>
  );
};