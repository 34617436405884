import {createContext, useContext} from 'react';
import {loadImgAsset} from '../helpers/loadImgAsset';
import {retrieveHeader} from '../helpers/HttpHeaders';

const TENANT_ID_HEADER = 'X-Tenant-Id';
const DEFAULT_TENANT_ID = process.env.REACT_APP_TENANT_ID;

const tenantId = retrieveHeader(TENANT_ID_HEADER) ?? DEFAULT_TENANT_ID;
const tenant = require(`./configs/${tenantId}/config.json`);

const favicon = await loadImgAsset(`/tenants/${tenantId}/imgs/favicon.png`);
const logo = await loadImgAsset(`/tenants/${tenantId}/imgs/logo.png`);

import(`./styles/${tenantId}/style.scss`);

const common = require(`./configs/config.json`);
const logoPRTR = await loadImgAsset(`/tenants/common/imgs/logoPRTR.jpg`);
const logoFundedByTheEuropeanUnion = await loadImgAsset(`/tenants/common/imgs/logoFundedByTheEuropeanUnion.jpg`);
const logoICECYL = await loadImgAsset(`/tenants/common/imgs/logoICECYL.jpg`);
const logoCofundationFEDER = await loadImgAsset(`/tenants/common/imgs/logoCofundationFEDER.jpg`);

const tenantConfig = {
  tenantId,
  favicon,
  logo,
  logoPRTR,
  logoICECYL,
  logoCofundationFEDER,
  logoFundedByTheEuropeanUnion,
  ...common,
  ...tenant
}

const TenantContext = createContext();

export const useTenantContext = () => {
  return useContext(TenantContext);
};

export const TenantContextProvider = ({ children }) => {
  return (
    <TenantContext.Provider value={tenantConfig}>
      {children}
    </TenantContext.Provider>
  )
};
