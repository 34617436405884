import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import {TextNotFount} from '../../components/Theme/components/cardArticulo.elements';
import {Cardsection} from '../../ui';
import React from 'react';
import {ProductPriceUI} from '../../product/ui/ProductPriceUI';
import {CatalogItemCard} from './CatalogItemCard';
import {ProductFlatPurchaseCardUI} from '../../product/ui/ProductFlatPurchaseCardUI';

export const CatalogUI = ({ data }) => {
  const { catalogItems, activeTab } = data;

  return (
    <>
      {
        catalogItems.length === 0 ? (
          <div className="text-center d-grid justify-content-center align-items-center">
            <div>
              <Inventory2OutlinedIcon />
            </div>
            <TextNotFount>No se han encontrado resultados. <br /> </TextNotFount>
            <a href={'/articulos'} className="text-primary"> Ver todos los productos</a>
          </div>
        ) : (
          <>
            {
              activeTab === 'grid' ? (
                <Cardsection>
                  {
                    catalogItems.map((catalogItem, index) => (
                      <div key={index}>
                        <CatalogItemCard data={{ catalogItem: catalogItem }}>
                          <CatalogItemCard.Price>
                            <ProductPriceUI
                              price={{
                                sellingPrice: catalogItem.price?.bestPrice,
                                basePrice: catalogItem.price?.value,
                              }} />
                          </CatalogItemCard.Price>
                        </CatalogItemCard>
                      </div>
                    ))
                  }
                </Cardsection>
              ) : (
                <div className="cardList">
                  {
                    catalogItems.map((catalogItem, index) => (
                      <div key={index} className="my-1">
                        <ProductFlatPurchaseCardUI details={catalogItem} />
                      </div>
                    ))
                  }
                </div>
              )
            }
          </>
        )
      }
    </>
  );
};