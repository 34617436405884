import {ProductSwiper} from './ProductSwiper';
import {fetchAll} from '../infrastructure/CatalogApi';
import {mapRemoteData} from '../../common/domain/RemoteData.model';

const FEATURED_PRODUCTS_FILTER = {
  featured: true
};

export const FeaturedProducts = () => {
  const fetchFn = () =>
    fetchAll(FEATURED_PRODUCTS_FILTER, { amount: 50, page: 0 })
    .then(rd => mapRemoteData(rd, ({ content }) => content));
  return (
    <ProductSwiper title={'PRODUCTOS DESTACADOS'} fetchFn={fetchFn} />
  );
};
