import React, {useState} from 'react';
import {ProductTagsUI} from './ProductTagsUI';
import {ImgSlider} from '../../ui/components/ImgSlider';
import {AddToFavoritesButton} from './AddToFavoritesButton';
import {ProductDescription} from './ProductDescription';
import {NameProduct} from './nameProduct';

export const ProductDetailsUI = ({ product }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="row gap-4 gap-lg-0"
         onMouseEnter={() => setIsHovered(true)}
         onMouseLeave={() => setIsHovered(false)}
    >
      <div className="col-12 order-lg-first row align-items-start gap-2">
        <div className="col-12">
          <div className="d-flex justify-content-center align-items-center">
            <div className="position-absolute right-50 translate-middle z-1">
              <AddToFavoritesButton data={{
                productCode: product.code,
                show: isHovered || true,
                isFavorite: product.isFavorite
              }} />
            </div>
            <div>
              <ImgSlider
                mainImgUrl={product.imageUrl}
                subImageUrls={product.secondaryImages}
              />
            </div>
          </div>
        </div>
        <div className="col-12 order-md-first">
          <div className="d-flex flex-column flex-xl-row gap-2 align-items-start mb-3">
            <div>
              <NameProduct title={product.name}
                           lab={product.manufacturer}
                           referencia={product.code}
              />
              {
                !!product?.cimavetFileUrl && (
                  <a target="_blank" rel="noreferrer" href={product.cimavetFileUrl}>Ficha técnica</a>
                )
              }
            </div>
            <ProductTagsUI productDetails={product} />
          </div>
        </div>
        <div className="col-12 d-flex flex-column gap-2">
          <ProductDescription description={product.description} />
        </div>
      </div>
    </div>
  );
};