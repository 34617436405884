import {ModalContend, ModalDetalleArticulo} from '../../ui';
import React, {useMemo, useState} from 'react';
import {BtnCloseCarrito} from '../../ui/components/navbar/NavbarStyles.elements';
import CloseIcon from '@mui/icons-material/Close';
import {PurchasePanel} from './PurchasePanel';
import {buildWithoutDeal} from '../domain/ProductSelection.model';
import {useCartContext} from '../../cart';
import {toast} from 'react-toastify';
import {AddUpdateButton} from '../../common/ui/AddUpdateButton';

export const AddToCartProxy = ({ data }) => {
  const { details, quantity } = data;
  const [modal, setModal] = useState(false);
  const { isOnCart, addProduct } = useCartContext();

  const disabled = useMemo(() =>
      !details.price || details.price?.bestPrice <= 0 || quantity <= 0,
    [details, quantity]
  );

  const handleAddToCart = async () => {
    if (details.hasDealsToChoose) {
      setModal(true);
    } else {
      addToCart().then();
    }
  };

  const addToCart = async () => {
    try {
      await addProduct(buildWithoutDeal(details, quantity));
      toast.success(`'${details.name || details.code}' agregado al carrito exitosamente.`);
    } catch (error) {
      toast.error(`'${details.name || details.code}' no pudo agregarse al carro. Por favor, reintente más tarde o contacte con nosotros.`);
    }
  };

  return (
    <div>
      {
        modal && (
          <ModalContend>
            <ModalDetalleArticulo>
              <div className="d-flex flex-row gap-2 align-content-center">
                <h3>{details.name}</h3>
                <BtnCloseCarrito onClick={() => setModal(false)}>
                  <CloseIcon />
                </BtnCloseCarrito>
              </div>

              <PurchasePanel product={details} defaultQuantity={quantity} afterAddToCart={() => {
                setModal(false);
              }} />
            </ModalDetalleArticulo>
          </ModalContend>
        )
      }
      <AddUpdateButton
        shouldAdd={isOnCart(details?.id)}
        disabled={disabled}
        onClick={() => handleAddToCart()}
      />
    </div>
  );
};