import React from 'react';
import {FooterContent, FooterInfo, LiFooter, TextTelefono, UlFooter, UlFooterInfo} from './footer.elements';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {Link} from 'react-router-dom';
import {useTenantContext} from '../../../tenant/TenantContext';
import styled from 'styled-components';

const FooterLegalText = styled.div`
  text-align: center;
  font-size: 0.75em;
  max-width: 600px;
  margin: 2rem auto 0 auto;

  p {
    margin-bottom: 1rem;
  }

  .logos {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
  }

  img {
    display: block;
    max-height: 120px;
    max-width: 400px;
    width: auto;
    height: auto;
    object-fit: contain;
    padding: 4px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 2rem 1rem;
    max-width: 100%;

    img {
      max-height: 100px;
      max-width: 300px;
    }
  }
`;

function Footer(props) {
  const {
    primaryColor,
    businessName,
    tel,
    address,
    about,
    privacyPolicy,
    cookiesPolicy,
    logoPRTR,
    logoICECYL,
    logoCofundationFEDER,
    logoFundedByTheEuropeanUnion,
    legalText
  } = useTenantContext();

  const currentYear = new Date().getFullYear();
  return (
    <FooterInfo>
      <FooterContent>
        <div>
          <UlFooter>
            <LiFooter>
              <a target="_blank" rel="noreferrer"
                 href={about}>Sobre {businessName}</a>
            </LiFooter>
            <LiFooter>
              <a target="_blank" rel="noreferrer" href={privacyPolicy}>Politicas
                de privacidad
              </a>
            </LiFooter>
            <LiFooter>
              <a target="_blank" rel="noreferrer" href={cookiesPolicy}> Politicas
                de cookies
              </a>
            </LiFooter>
            {props.publicFooter ?
             <></> : <><LiFooter><Link to="/articulos">Catálogo de productos</Link></LiFooter>
                <LiFooter><Link to={'/laboratorios'}>Fabricantes</Link></LiFooter>
             </>
            }
          </UlFooter>
        </div>
        <div>
          <UlFooterInfo>
            <LiFooter>
              <PhoneIcon style={{ color: primaryColor }} /><span>Teléfono: </span>
              <TextTelefono>{tel}</TextTelefono>
            </LiFooter>
            <LiFooter>
              <LocationOnIcon style={{ color: primaryColor }} />
              <span>{address.split('-')[0]}<br />
                {address.split('-')[1]}</span>
            </LiFooter>
          </UlFooterInfo>
        </div>

        <FooterLegalText>
          {legalText}
          <div className="logos">
            <img src={logoPRTR} alt="Plan de Recuperación y Transformación" />
            <img src={logoFundedByTheEuropeanUnion} alt="Fundado por la Unión Europea" />
            <img src={logoICECYL} alt="ICE Castilla y León" />
            <img src={logoCofundationFEDER} alt="Cofundación FEDER" />
          </div>
        </FooterLegalText>
      </FooterContent>
      <div className="justify-content-center col-md-12 mb-4">
        <hr />
        {currentYear} © {businessName}
      </div>

    </FooterInfo>
  );
}

export {Footer};