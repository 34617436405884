import {useManufacturerContext} from '../../context/ManufacturerContext';
import {ProductSwiper} from '../../../catalog/ui/ProductSwiper';
import React from 'react';
import {fetchAll} from '../../../catalog/infrastructure/CatalogApi';
import {mapRemoteData} from '../../../common/domain/RemoteData.model';

export const ManufacturerProducts = () => {
  const { details } = useManufacturerContext();

  if (details?.codigo) {
    const fetchFn = () =>
      fetchAll({ manufacturers: [details.codigo] }, { amount: 50, page: 0 })
      .then(rd => mapRemoteData(rd, ({ content }) => content));
    return (
      <ProductSwiper title="PRODUCTOS DEL FABRICANTE" fetchFn={fetchFn} />
    );
  }
};