import {requestRemoteData} from '../../API/api';
import {mapRemoteData} from '../../common/domain/RemoteData.model';

const BANNER_ENDPOINT = 'offer';

/**
 * @returns {Promise<RemoteData<Banner>>}
 */
export const fetchAllBanners = () => {
  return requestRemoteData(BANNER_ENDPOINT).then(parseBanners);
};

const parseBanners = (rd) => {
  const mapperFn = banners => banners?.map(banner => ({
    title: banner.nombre,
    imageUrl: banner.imagen,
    link: banner.link
  }))
  return mapRemoteData(rd, mapperFn);
};