import {changeProductFavorite} from '../../product/application/changeProductFavorite';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {initial, isPending, isSuccess} from '../../common/domain/RemoteData.model';
import {remoteDataWithSetter} from '../../common/utils/remoteDataWithSetter';

export const useItemFavorite = (catalogItem) => {
  const [changeFavoriteRemoteData, setChangeFavoriteRemoteData] = useState(initial(null));
  const [isFavorite, setIsFavorite] = useState(catalogItem.isFavorite);

  useEffect(() => {
    if (isSuccess(changeFavoriteRemoteData)) {
      setIsFavorite(changeFavoriteRemoteData.value);
    }
  }, [changeFavoriteRemoteData]);

  const postUpdate = useMemo(() =>
      () => changeProductFavorite(catalogItem.code, isFavorite),
    [catalogItem.code, isFavorite]
  );

  const changeFavorite = useCallback(async () => {
    if (!isPending(changeFavoriteRemoteData)) {
      await remoteDataWithSetter(setChangeFavoriteRemoteData, postUpdate);
    }
  }, [changeFavoriteRemoteData, postUpdate]);

  return { isFavorite, changeFavorite };
};