import React, {useEffect, useState} from 'react';
import {initial} from '../../common/domain/RemoteData.model';
import {remoteDataWithSetter} from '../../common/utils/remoteDataWithSetter';
import {RemoteDataUI} from '../../common/ui/RemoteDataUI';
import {Swiper} from './Swiper';
import {CatalogItemCard} from './CatalogItemCard';
import {ProductPriceUI} from '../../product/ui/ProductPriceUI';
import {Spinner} from 'react-bootstrap';

export const ProductSwiper = ({ title, fetchFn }) => {
  const [productsRemoteData, setProductsRemoteData] = useState(initial([]));

  useEffect(() => {
    if (!!fetchFn) {
      remoteDataWithSetter(setProductsRemoteData, fetchFn).then();
    }
  }, [fetchFn]);

  return (
    <div className="row">
      <div className="col-12 align-self-start">
        <p className="bold text-primary fs-5">{title}</p>
      </div>
      <div className="col-12 p-3 rounded-2">
        <RemoteDataUI remoteData={productsRemoteData}>
          <RemoteDataUI.Success>
            {
              products =>
                <>
                  {
                    products.length === 0 ? (
                      <div className="alert alert-light">
                        No hay productos
                      </div>
                    ) : (
                      <Swiper>
                        {
                          products.map((product, index) => (
                            <Swiper.Item  key={index}>
                              <CatalogItemCard data={{ catalogItem: product }}>
                                <CatalogItemCard.Price>
                                  <ProductPriceUI
                                    price={{
                                      sellingPrice: product.price?.bestPrice,
                                      basePrice: product.price?.value
                                    }} />
                                </CatalogItemCard.Price>
                              </CatalogItemCard>
                            </Swiper.Item>
                          ))
                        }
                      </Swiper>
                    )
                  }
                </>
            }
          </RemoteDataUI.Success>
          <RemoteDataUI.Pending>
            <div className="text-center m-4"><Spinner /></div>
          </RemoteDataUI.Pending>
          <RemoteDataUI.Failure>
            <div className="alert alert-danger">Imposible cargar productos.</div>
          </RemoteDataUI.Failure>
        </RemoteDataUI>
      </div>
    </div>
  );
};