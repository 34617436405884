import ApiClient, {requestRemoteData} from '../../API/api';
import HttpRequestError from '../../API/models/HttpRequestError';
import {mapRemoteData} from '../../common/domain/RemoteData.model';

const FAVORITE_ENDPOINT = 'account/favorites';
const GET_PRODUCT_ENDPOINT = 'products';

export const addToFavorites = (productCode) => {
  return requestRemoteData(
    FAVORITE_ENDPOINT,
    { method: 'POST', queryParams: { cod_articulo: productCode } }
  );
};

export const removeFromFavorites = (productCode) => {
  return requestRemoteData(
    FAVORITE_ENDPOINT,
    { method: 'DELETE', queryParams: { cod_articulo: productCode } }
  );
};

const parseGetProduct = (response) => {
  if (response.status > 300) {
    throw new HttpRequestError('Failed to get product', JSON.stringify(response));
  }
  return response;
};

export const fetchProduct = (productCode) => {
  return requestRemoteData(
    GET_PRODUCT_ENDPOINT,
    { queryParams: { code: productCode } }
  ).then(rd => mapRemoteData(rd, parseGetProduct));
};

export const changeFavorite = (productCode, favorite) => {
  const method = favorite ? 'DELETE' : 'POST';
  return ApiClient.performRequest(
    FAVORITE_ENDPOINT,
    { method, queryParams: { cod_articulo: productCode } }
  );
};

/**
 * TODO: move to the catalog domain
 * TODO: use remoteData fetch
 * @returns {Promise<*>}
 */
export const getFavorites = () => {
  return ApiClient.performRequest(FAVORITE_ENDPOINT);
};

