import {requestRemoteData} from '../../API/api';

const RELATED_PRODUCTS_ENDPOINT = 'product/related';
const CATALOG_ENDPOINT = 'products';
const FAVORITES_ENDPOINT = 'account/favorites';

export const fetchRelatedProducts = (productCode, { page, amount }) => {
  return requestRemoteData(
    RELATED_PRODUCTS_ENDPOINT,
    {
      queryParams: { page: page, products_per_page: amount, productCode: productCode }
    }
  );
};

/**
 * @param {Filters} filters
 * @param {Config} config
 * @returns {Promise<RemoteData<ApiResponse<Product>>>}
 */
export const fetchAll = (filters, config) => {
  return requestRemoteData(
    CATALOG_ENDPOINT,
    {
      method: 'POST',
      body: {
        searchText: filters?.search || '',
        needsPrescription: filters?.vetPrescription,
        species: filters?.species || [],
        manufacturers: filters?.manufacturers || [],
        macrofamily: filters?.macroFamily || '',
        family: filters?.family || '',
        subfamilies: filters?.subFamilies || [],
        isFeatured: filters?.featured || null,
        relatedToProductCode: filters.relatedToProductCode || '',
        size: config.amount,
        page: config.page
      }
    }
  );
};

export const fetchFavorites = () => {
  return requestRemoteData(
    FAVORITES_ENDPOINT,
    { headers: { 'X-Api-Version': '2' } }
  );
};

