import type {Product} from '../../cart/models/Product';
import {calculateDiscountPercentage} from '../../helpers/calculateDiscountPercentage';
import React from 'react';
import {DownloadableTable} from '../../ui/styles/DownloadableTable';

export const DownloadableProductTableUI = ({ products }) => {
  const calcTotalPrice = (product: Product): number => {
    const price = product.quantity * product.sellingPrice;
    return price + ((price * product.tax) / 100);
  };

  return (
    <DownloadableTable>
      <thead>
      <tr>
        <th>Ref.</th>
        <th>Descripción</th>
        <th>Cantidad</th>
        <th>Precio Ud(s)</th>
        <th>% IVA</th>
        <th>% Dto.</th>
        <th>Total</th>
      </tr>
      </thead>
      <tbody>
      {
        products.map((product, index) => (
          <tr key={index}>
            <td>{product.code}</td>
            <td>{!!product.name ? product.name : 'N/A'}</td>
            <td>{product.stock}</td>
            <td>{product.sellingPrice.toFixed(2)}€</td>
            <td>{product.tax.toFixed(2)}</td>
            <td>{calculateDiscountPercentage(product.basePrice, product.sellingPrice).toFixed(2)}</td>
            <td>{calcTotalPrice(product).toFixed(2)}€</td>
          </tr>
        ))
      }
      </tbody>
    </DownloadableTable>
  );
};

