import {Navbar} from '../ui/components/navbar/Navbar';
import {ProductSearchBar} from '../ui/components/navbar/component/Search';
import React from 'react';
import {AccountButton} from '../user/ui/AccountButton';
import {CartPreviewButton} from '../cart/components/CartPreviewButton';
import {PendingNotificationPreviewButton} from '../notification/components/PendingNotificationPreviewButton';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {useNavigate} from 'react-router-dom';
import {SearchLinksFilter} from '../catalog/ui/SearchLinksFilter';

export const NavbarComposer = () => {
  const navigate = useNavigate();

  return (
    <Navbar>
      <Navbar.Controls>
        <div className="d-flex flex-row gap-md-2 align-items-center justify-content-end">
          <AccountButton />
          <button type="button" className="btn p-0" onClick={() => navigate('/cuenta/Favoritos')}>
            <FavoriteBorderIcon fontSize="large" className="text-primary" />
          </button>
          <PendingNotificationPreviewButton />
          <CartPreviewButton />
        </div>
      </Navbar.Controls>
      <Navbar.Search>
        <ProductSearchBar />
      </Navbar.Search>
      <Navbar.Menu>
        <SearchLinksFilter />
      </Navbar.Menu>
    </Navbar>
  );
};